<script setup lang="ts">
import { useAuthStore } from '@/stores/auth';
import AccountMenu from '@/components/Account/AccountMenu.vue';
import GlossaryButton from '@/components/core/GlossaryButton.vue';

const config = useRuntimeConfig();
const { loggedIn } = storeToRefs(useAuthStore());

const homeUrl = computed(() => config.public.homePageUrl);

const landingUrl = computed(() => config.public.landingPageUrl);

const logoUrl = computed(() => {
  return loggedIn.value ? homeUrl.value : landingUrl.value;
});

const bridge128Url = computed(() => config.public.bridge128Url);
</script>

<template>
  <header class="bg-brand-navy-600 py-5">
    <Container>
      <div class="flex flex-row justify-between items-center">
        <div class="hidden md:flex lg:w-auto items-center">
          <NuxtLink :to="logoUrl" class="logo">
            <NuxtIcon name="logo-with-text" filled />
          </NuxtLink>
          <span class="text-white text-xs ml-1">by Bridge128</span>
        </div>
        <NuxtLink :to="logoUrl" class="logo-mobile">
          <NuxtIcon name="logo-with-text-mobile" filled />
        </NuxtLink>
        <nav
          class="flex-row-reverse md:flex-row flex items-center gap-2.5 md:gap-4"
        >
          <AccountMenu />
          <Button
            :to="bridge128Url"
            target="_blank"
            color="white"
            class-name="!px-9 !font-medium"
          >
            Learn
          </Button>
          <GlossaryButton />
        </nav>
      </div>
    </Container>
  </header>
</template>
<style lang="scss" scoped>
.logo {
  :deep(.nuxt-icon svg) {
    @apply w-[152px] h-12 mb-0;
  }
}
.logo-mobile {
  @apply md:hidden;

  :deep(.nuxt-icon svg) {
    @apply w-[133px] h-[42px] mb-0;
  }
}
</style>
